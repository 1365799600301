import { default as chronologyXXwsbAU1aAMeta } from "/var/www/sakhfood-admin.dev.sitisit.ru/frontend/sakhfood-admin-front-20240930012915/pages/chronology.vue?macro=true";
import { default as helpgkEW8tPfS0Meta } from "/var/www/sakhfood-admin.dev.sitisit.ru/frontend/sakhfood-admin-front-20240930012915/pages/help.vue?macro=true";
import { default as history9qsr4fZnZhMeta } from "/var/www/sakhfood-admin.dev.sitisit.ru/frontend/sakhfood-admin-front-20240930012915/pages/history.vue?macro=true";
import { default as indexuvl70E8tdEMeta } from "/var/www/sakhfood-admin.dev.sitisit.ru/frontend/sakhfood-admin-front-20240930012915/pages/index.vue?macro=true";
import { default as menuSpZW61rpx1Meta } from "/var/www/sakhfood-admin.dev.sitisit.ru/frontend/sakhfood-admin-front-20240930012915/pages/menu.vue?macro=true";
import { default as reports0y8Rns4WtkMeta } from "/var/www/sakhfood-admin.dev.sitisit.ru/frontend/sakhfood-admin-front-20240930012915/pages/reports.vue?macro=true";
import { default as reviewsyLT0DxeK3eMeta } from "/var/www/sakhfood-admin.dev.sitisit.ru/frontend/sakhfood-admin-front-20240930012915/pages/reviews.vue?macro=true";
import { default as settings6UqDWsExtPMeta } from "/var/www/sakhfood-admin.dev.sitisit.ru/frontend/sakhfood-admin-front-20240930012915/pages/settings.vue?macro=true";
import { default as workersDPI2BqFLQiMeta } from "/var/www/sakhfood-admin.dev.sitisit.ru/frontend/sakhfood-admin-front-20240930012915/pages/workers.vue?macro=true";
export default [
  {
    name: chronologyXXwsbAU1aAMeta?.name ?? "chronology",
    path: chronologyXXwsbAU1aAMeta?.path ?? "/chronology",
    meta: chronologyXXwsbAU1aAMeta || {},
    alias: chronologyXXwsbAU1aAMeta?.alias || [],
    redirect: chronologyXXwsbAU1aAMeta?.redirect,
    component: () => import("/var/www/sakhfood-admin.dev.sitisit.ru/frontend/sakhfood-admin-front-20240930012915/pages/chronology.vue").then(m => m.default || m)
  },
  {
    name: helpgkEW8tPfS0Meta?.name ?? "help",
    path: helpgkEW8tPfS0Meta?.path ?? "/help",
    meta: helpgkEW8tPfS0Meta || {},
    alias: helpgkEW8tPfS0Meta?.alias || [],
    redirect: helpgkEW8tPfS0Meta?.redirect,
    component: () => import("/var/www/sakhfood-admin.dev.sitisit.ru/frontend/sakhfood-admin-front-20240930012915/pages/help.vue").then(m => m.default || m)
  },
  {
    name: history9qsr4fZnZhMeta?.name ?? "history",
    path: history9qsr4fZnZhMeta?.path ?? "/history",
    meta: history9qsr4fZnZhMeta || {},
    alias: history9qsr4fZnZhMeta?.alias || [],
    redirect: history9qsr4fZnZhMeta?.redirect,
    component: () => import("/var/www/sakhfood-admin.dev.sitisit.ru/frontend/sakhfood-admin-front-20240930012915/pages/history.vue").then(m => m.default || m)
  },
  {
    name: indexuvl70E8tdEMeta?.name ?? "index",
    path: indexuvl70E8tdEMeta?.path ?? "/",
    meta: indexuvl70E8tdEMeta || {},
    alias: indexuvl70E8tdEMeta?.alias || [],
    redirect: indexuvl70E8tdEMeta?.redirect,
    component: () => import("/var/www/sakhfood-admin.dev.sitisit.ru/frontend/sakhfood-admin-front-20240930012915/pages/index.vue").then(m => m.default || m)
  },
  {
    name: menuSpZW61rpx1Meta?.name ?? "menu",
    path: menuSpZW61rpx1Meta?.path ?? "/menu",
    meta: menuSpZW61rpx1Meta || {},
    alias: menuSpZW61rpx1Meta?.alias || [],
    redirect: menuSpZW61rpx1Meta?.redirect,
    component: () => import("/var/www/sakhfood-admin.dev.sitisit.ru/frontend/sakhfood-admin-front-20240930012915/pages/menu.vue").then(m => m.default || m)
  },
  {
    name: reports0y8Rns4WtkMeta?.name ?? "reports",
    path: reports0y8Rns4WtkMeta?.path ?? "/reports",
    meta: reports0y8Rns4WtkMeta || {},
    alias: reports0y8Rns4WtkMeta?.alias || [],
    redirect: reports0y8Rns4WtkMeta?.redirect,
    component: () => import("/var/www/sakhfood-admin.dev.sitisit.ru/frontend/sakhfood-admin-front-20240930012915/pages/reports.vue").then(m => m.default || m)
  },
  {
    name: reviewsyLT0DxeK3eMeta?.name ?? "reviews",
    path: reviewsyLT0DxeK3eMeta?.path ?? "/reviews",
    meta: reviewsyLT0DxeK3eMeta || {},
    alias: reviewsyLT0DxeK3eMeta?.alias || [],
    redirect: reviewsyLT0DxeK3eMeta?.redirect,
    component: () => import("/var/www/sakhfood-admin.dev.sitisit.ru/frontend/sakhfood-admin-front-20240930012915/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: settings6UqDWsExtPMeta?.name ?? "settings",
    path: settings6UqDWsExtPMeta?.path ?? "/settings",
    meta: settings6UqDWsExtPMeta || {},
    alias: settings6UqDWsExtPMeta?.alias || [],
    redirect: settings6UqDWsExtPMeta?.redirect,
    component: () => import("/var/www/sakhfood-admin.dev.sitisit.ru/frontend/sakhfood-admin-front-20240930012915/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: workersDPI2BqFLQiMeta?.name ?? "workers",
    path: workersDPI2BqFLQiMeta?.path ?? "/workers",
    meta: workersDPI2BqFLQiMeta || {},
    alias: workersDPI2BqFLQiMeta?.alias || [],
    redirect: workersDPI2BqFLQiMeta?.redirect,
    component: () => import("/var/www/sakhfood-admin.dev.sitisit.ru/frontend/sakhfood-admin-front-20240930012915/pages/workers.vue").then(m => m.default || m)
  }
]